import Layout from "./common/layout"
import Marketing from "./service/Marketing/Marketing"
import Head from "./common/Head"
import { useFormatter } from "helpers/i18n"

const ServiceMarketing = () => {
  const { currentLocale } = useFormatter()

  return (
    <>
      <Head
        showCaptcha={true}
        title={
          currentLocale === "en"
            ? "Digital marketing services with data-driven solutions"
            : "Dịch vụ marketing trực tuyến dựa trên dữ liệu"
        }
        description={
          currentLocale === "en"
            ? "OnPoint offers a wide range of digital marketing services through data-driven, enabling you to select the ones that best suit your needs."
            : "OnPoint cung cấp các dịch vụ marketing trực tuyến dựa trên dữ liệu, cho phép nhãn hàng chọn giải pháp phù hợp nhất với nhu cầu."
        }
        image={
          "https://s3.ap-southeast-1.amazonaws.com/public.onpoint.vn/service-1-meta.png"
        }
      />
      <Layout>
        <Marketing />
      </Layout>
    </>
  )
}

export default ServiceMarketing
