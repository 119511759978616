import { useFormatter } from "helpers/i18n"
import new1 from "images/Service/Marketing/service-1-production-1.png"
import new2 from "images/Service/Marketing/service-1-production-2.png"
import new3 from "images/Service/Marketing/service-1-production-3.png"
import ImageZoomEffect from "pages/common/ImageZoomEffect"
import "./CreativeProduction.scss"

export default function CreativeProduction() {
  const { __ } = useFormatter()
  const list = [
    {
      image: new1,
      title: `${__({
        defaultMessage: "Creative & Design",
      })}`,
      description: `${__({
        defaultMessage:
          "Produce unique and memorable designs that stand out so that shoppers may easily identify your brand or product. Our in-house art team use a keen sense of creativity, in line with brand and platform guideline to create a wide range of designs that capture your shoppers attention effectively.",
      })}`,
    },
    {
      image: new2,
      title: `${__({
        defaultMessage: "Content Marketing ",
      })}`,
      description: `${__({
        defaultMessage:
          "Tell compelling brand stories with an aim to informing, entertaining, and connecting at every stage of the customer journey. We develop content marketing strategies that create undeniable value for your audience and make you the go-to brand they trust.",
      })}`,
    },
    {
      image: new3,
      title: `${__({
        defaultMessage: "Production Livestream & Video",
      })}`,
      description: `${__({
        defaultMessage:
          "Live in the now, reach a massive audience and experience instant results. We have experience in creating an exciting yet smooth live atmosphere with shoppertainment content on multi-channel livestream platforms such as Facebook and e-commerce platforms for your promotion or product launching events.",
      })}`,
    },
  ]
  return (
    <div className="creative_production">
      {list.map((data, index) => {
        return <ContentItem {...data} key={index} />
      })}
    </div>
  )
}

function ContentItem({ image, title, description }) {
  return (
    <div className="creative_production__detail">
      <div className="creative_production__detail__image">
        <ImageZoomEffect src={image} alt="" />
      </div>
      <div className="creative_production__detail__title">{title}</div>
      <div className="creative_production__detail__description">
        {description}
      </div>
    </div>
  )
}
