import { useFormatter } from "helpers/i18n"
import Template2Colums from "pages/common/Template2Colums"
import image1 from "images/Service/Marketing/Service_1_image.jpg"
import "./MarketingContent.scss"

export default function MarketingContent() {
  const { __ } = useFormatter()
  const contents = [
    {
      id: 1,
      title: `${__({
        defaultMessage: "On-Platform Media Performance",
      })}`,

      content: `${__({
        defaultMessage:
          "Attract shoppers to your e-commerce stores on various platforms and ensure the best ROI through advertising, affiliate marketing, and marketing package buying implemented by our experienced team.",
      })}`,
    },
    {
      id: 2,
      title: `${__({
        defaultMessage: "Off-Platform Media Performance",
      })}`,

      content: `${__({
        defaultMessage:
          "Maximize marketing effectiveness in a digital age by performance campaigns run on social media channels and Google. We combine various campaign types and advertising formats to drive traffic to your e-commerce shops and increase conversions.",
      })}`,
    },
    {
      id: 3,
      title: `${__({
        defaultMessage: "Social Media Marketing",
      })}`,

      content: `${__({
        defaultMessage:
          "Approach your target audience on social media platforms such as Facebook (both fanpage and community group), Instagram and TikTok with shopper-centric content to expand your customer base and improve engagement rate.",
      })}`,
    },
    {
      id: 4,
      title: `${__({
        defaultMessage: "Influencer Marketing",
      })}`,

      content: `${__({
        defaultMessage:
          "Take advantage of bloggers and influencers in various segments & categories to get your brand in front of a massive targeted audience. We not only collaborate with different types of influencers but also top-notch experts and professionals.",
      })}`,
    },
  ]
  return (
    <Template2Colums alignType="right" dataTexts={contents} image={image1} />
  )
}
