import BannerHeader from "pages/common/BannerHeader"
import GetStart from "pages/common/GetStart"
import WhyNew from "pages/product/Common/WhyNew"

import image1 from "images/Service/Marketing/image-1.svg"
import { StaticImage } from "gatsby-plugin-image"
import { useFormatter } from "helpers/i18n"
import bannerMobile from "images/Service/Marketing/service-1-banner-mobile.png"
import ContentWrapper from "pages/common/ContentWrapper"

import WhatWeOfferTemplate from "pages/product/Common/WhatWeOfferTemplate"
import MarketingContent from "./components/MarketingContent"
import CreativeProduction from "./components/CreativeProduction"
import "./Marketing.scss"

export default function Marketing() {
  const { __ } = useFormatter()
  const banner = "../../../images/Service/Marketing/service-1-banner.png"

  return (
    <>
      <div>
        <BannerHeader
          breadcrumb={[
            {
              label: `${__({ defaultMessage: "Home/" })}`,
              url: "/",
            },
            {
              label: `${__({ defaultMessage: "Services/" })}`,
            },
            {
              label: `${__({
                defaultMessage: "E-Marketing Management",
              })}`,
            },
          ]}
          banner={
            <StaticImage
              layout="fullWidth"
              src={banner}
              alt="E-Marketing Management Banner"
              decoding="async"
              fetchpriority="high"
              quality={100}
            />
          }
          mobileBanner={
            <img src={bannerMobile} alt="" style={{ width: "100%" }} />
          }
          logo={""}
          listTitle={[
            `${__({
              defaultMessage: "E-Marketing Management",
            })}`,
          ]}
          description={`${__({
            defaultMessage:
              "We propose a wide range of digital marketing services through data-driven and audience focused solutions, enabling you to select the ones that best suit your needs and goals. We ensure that your brands life cycle continues to grow and thrive online.",
          })}`}
        />

        {[
          <WhatWeOfferTemplate
            title={__({ defaultMessage: "Media & Influencer Marketing" })}
            description={__({
              defaultMessage:
                "We provide performance-based marketing solutions with the right mix of advertising media channels and optimize process to achieve sustainable business goals.",
            })}
          />,
          <MarketingContent />,
          <WhatWeOfferTemplate
            title={`
            ${__({
              defaultMessage: "Creative & Content Production",
            })}`}
            description={`
            ${__({
              defaultMessage:
                "We develop scalable content marketing strategies for every stage of your funnel and every milestone of your customer’s journey.",
            })}
            `}
            content={CreativeProduction()}
          />,
        ].map((component, key) => (
          <section key={key} className={key > 0 ? "section" : null}>
            <div className="reveal">{component}</div>
          </section>
        ))}
        <GetStart />
      </div>
    </>
  )
}
